import { CDN_URL_IMAGES } from '@/configs';

export interface ILabItemContent {
  image: string;
  title: string;
  content: string;
  link: string;
  disabled?: boolean;
}

export const Portfolio: ILabItemContent[] = [
  {
    image: `${CDN_URL_IMAGES}/apps/bitcoin-virtual-machine.jpeg`,
    title: 'Bitcoin Virtual Machine (BVM)',
    content: 'Smart contracts on Bitcoin',
    link: 'https://bvm.network',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/alpha.jpeg`,
    title: 'Alpha',
    content: 'SocialFi on Bitcoin',
    link: 'https://alpha.wtf/',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/nakaswap.jpeg`,
    title: 'Naka (Stealth)',
    content: 'DeFi on Bitcoin',
    link: 'https://nakaswap.org',
    disabled: true,
  },
  {
    image: `${CDN_URL_IMAGES}/apps/bitcoin-arcade.jpeg`,
    title: 'Bitcoin Arcade (Stealth)',
    content: 'GameFi on Bitcoin',
    link: '#',
    disabled: true,
  },
  {
    image: `${CDN_URL_IMAGES}/apps/generative.jpeg`,
    title: 'Generative',
    content: 'NFTs on Bitcoin',
    link: 'https://generative.xyz/',
  },
  {
    image: `${CDN_URL_IMAGES}/perceptrons-v2.jpeg`,
    title: 'Perceptrons',
    content: 'AI on Bitcoin',
    link: 'https://generative.xyz/ai',
  },
];

export const Research: ILabItemContent[] = [
  {
    image: `${CDN_URL_IMAGES}/apps/bitcoin-virtual-machine.jpeg`,
    title: 'Recursive Rollups on Bitcoin',
    content: 'Scale Bitcoin indefinitely',
    link: 'https://twitter.com/punk3700/status/1703819001510682709',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/metamask-for-bitcoin.jpeg`,
    title: 'MetaMask for Bitcoin',
    content: 'Use MetaMask to manage Ordinal Inscriptions',
    link: 'https://twitter.com/punk3700/status/1628424255171096577',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/psbt.jpeg`,
    title: 'Smart Contracts for Bitcoin',
    content: 'Build unstoppable applications on Bitcoin',
    link: 'https://twitter.com/punk3700/status/1650524119136628736',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/smart-contracts.jpeg`,
    title: 'Bitcoin File System',
    content: 'Decentralized onchain storage system',
    link: 'https://twitter.com/punk3700/status/1669014135206731776',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/bitcoin-arcade.jpeg`,
    title: 'Fully Onchain Games',
    content: 'Unstoppable games',
    link: 'https://twitter.com/punk3700/status/1672259824191512576',
  },
];
export const OpenSource: ILabItemContent[] = [
  {
    image: `${CDN_URL_IMAGES}/apps/metamask-for-bitcoin.jpeg`,
    title: 'MetaMask for Bitcoin',
    content: 'Use MetaMask to manage Ordinal Inscriptions',
    link: 'https://github.com/generative-xyz/metamask-for-bitcoin/blob/main/metamask.for.bitcoin.ts',
  },
  {
    image: `${CDN_URL_IMAGES}/apps/psbt.jpeg`,
    title: 'PSBT',
    content: 'Sweep, buy, and sell Ordinal Inscriptions trustlessly',
    link: 'https://github.com/generative-xyz/generative-sdk/blob/main/src/bitcoin/psbt.ts',
  },
];
